import React, { useState } from "react"
import VisibilitySensor from "react-visibility-sensor"

function RoadmapItem({ isRight, ...props }) {
  const [isVisible, setIsVisible] = useState(false)

  function handleVisibilityChange(isVisible) {
    setIsVisible(v => isVisible || v)
  }

  const divClassName =
    "flex-1 pb-10 transition opacity-0 " + (isVisible ? "opacity-100" : "")

  return (
    <VisibilitySensor onChange={handleVisibilityChange}>
      <li className="flex max-w-xl m-auto items-stretch">
        <div className={divClassName}>
          {!!isRight && <RoadmapItem.Content {...props} />}
        </div>
        <div className="w-10 flex-none flex justify-center items-stretch relative transform translate-y-2 z-10">
          <div className="w-2 bg-primary" />
          <div className="absolute w-4 h-4 inset-x-0 m-auto bg-secondary rounded" />
        </div>
        <div className={divClassName}>
          {!isRight && <RoadmapItem.Content {...props} />}
        </div>
      </li>
    </VisibilitySensor>
  )
}

RoadmapItem.Content = ({ title, description }) => (
  <>
    <h3 className="text-2xl pb-2 font-bold">{title}</h3>
    <p>{description}</p>
  </>
)

export default RoadmapItem
