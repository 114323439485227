import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../layouts/en"
import { toRoadmapItems } from "../components/view-models/toRoadmapItem"
import RoadmapItem from "../components/roadmap-item"

const RoadmapEnPage = ({ data: { site, allRoadmapJson }, location }) => {
  const roadmapItems = toRoadmapItems(allRoadmapJson)
  return (
    <Layout location={location}>
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <h2 className="text-4xl mb-20">Roadmap &darr;</h2>
      <ul className="">
        {roadmapItems.map((i, index) => (
          <RoadmapItem isRight={index % 2} key={i.id} {...i} />
        ))}
      </ul>
    </Layout>
  )
}

export default RoadmapEnPage
export const pageQuery = graphql`
  query roadmapEnPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allRoadmapJson {
      edges {
        node {
          id
          title
          description
        }
      }
    }
  }
`
